.hero {
  position: relative;
  background-color: #fff;
  color: #000;
  overflow: hidden; }
  .hero .row {
    height: 100vh; }
    @media (min-width: 768px) {
      .hero .row {
        padding-right: calc(55% - 10px);
        padding-left: calc(15% - 10px); } }
    @media (min-width: 1200px) {
      .hero .row {
        padding-right: calc(55% - 10px);
        padding-left: calc(15% - 15px); } }
    @media screen and (max-height: 600px) {
      .hero .row {
        height: 130vh; } }

.slider-box {
  position: relative; }
  .slider-box::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 95%;
    background: linear-gradient(90deg, white 0%, rgba(255, 255, 255, 0) 57%);
    content: ""; }
    @media (max-width: 1199.98px) {
      .slider-box::after {
        display: none; } }
  .slider-box::before {
    position: absolute;
    top: 0;
    right: 0;
    width: 60px;
    height: 95%;
    background: linear-gradient(270deg, white 0%, rgba(255, 255, 255, 0) 57%);
    content: "";
    z-index: 100; }
    @media (max-width: 1199.98px) {
      .slider-box::before {
        display: none; } }
  .slider-box .slider-controls {
    width: 50%; }

.notis-slider .item {
  outline: none !important; }
  @media (max-width: 1199.98px) {
    .notis-slider .item {
      transform: translateX(0); } }

@media (min-width: 1200px) {
  .press-content {
    margin-right: -7.5rem; } }

.notis {
  position: relative;
  max-width: 400px;
  margin-bottom: 20px;
  border-radius: 15px;
  background: #a7a9ab;
  color: #000; }
  .notis a {
    display: block;
    color: #000; }
    .notis a:hover {
      text-decoration: none; }
  .notis .img-box {
    position: relative;
    width: 100%;
    min-height: 300px;
    border-radius: 15px;
    background-size: cover;
    opacity: 1;
    cursor: pointer;
    z-index: 100; }
    .notis .img-box .youtube-icon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 70px;
      margin: auto;
      transition: 0.3s all;
      pointer-events: none; }
    .notis .img-box:hover .youtube-icon {
      transform: scale(1.2); }
  .notis img {
    display: block; }
  .notis .video-box {
    display: none;
    position: relative;
    height: 300px;
    border-radius: 15px;
    overflow: hidden; }
  .notis h6 {
    font-weight: 500; }
    @media (max-width: 767.98px) {
      .notis h6 {
        font-size: 1rem; } }
  .notis p {
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.3; }
    @media (max-width: 767.98px) {
      .notis p {
        font-size: 0.875rem; } }

.steps-tabs {
  position: relative;
  background-color: #fff;
  z-index: 10; }

ul.tabs {
  margin: 0;
  padding: 0;
  list-style: none; }
  @media (max-width: 767.98px) {
    ul.tabs {
      text-align: center; } }
  ul.tabs li {
    display: inline-block;
    width: 50%;
    margin: 0 -2px; }
  ul.tabs a {
    display: block;
    padding: 0.5em 1em;
    border-bottom: 1px solid #fff;
    opacity: 0.3;
    color: #fff;
    font-size: 1.5rem; }
    @media (max-width: 767.98px) {
      ul.tabs a {
        font-size: 1.125rem; } }
    ul.tabs a.active {
      opacity: 1; }
    ul.tabs a:hover {
      text-decoration: none; }

.press-title {
  font-size: 2.625rem; }
  @media (max-width: 1199.98px) {
    .press-title {
      font-size: 2.25rem; } }

.how-works p,
.tech p {
  max-width: 200px;
  margin: auto;
  background-color: #fff;
  font-size: 1.2rem; }
  @media (max-width: 991.98px) {
    .how-works p,
    .tech p {
      font-size: 1rem; } }
  @media (max-width: 767.98px) {
    .how-works p,
    .tech p {
      font-size: 0.8125rem; } }

@media (max-width: 767.98px) {
  .how-works img,
  .tech img {
    width: 60px; } }

@media (max-width: 767.98px) {
  .tech h4.w-200 {
    font-size: 1.125rem; } }

.tech .line {
  position: relative; }
  .tech .line:nth-child(1)::after {
    display: block;
    position: absolute;
    top: 45px;
    right: -90px;
    width: 170px;
    height: 1px;
    margin: auto;
    border: 1px dashed #575a5d;
    content: "";
    z-index: -1; }
    @media (max-width: 1199.98px) {
      .tech .line:nth-child(1)::after {
        right: -55px;
        width: 90px; } }
    @media (max-width: 991.98px) {
      .tech .line:nth-child(1)::after {
        right: -55px;
        width: 100px; } }
  .tech .line:nth-child(4)::after {
    display: block;
    position: absolute;
    top: 55px;
    right: -90px;
    width: 170px;
    height: 1px;
    margin: auto;
    border: 1px dashed #575a5d;
    content: "";
    z-index: -1; }
    @media (max-width: 1199.98px) {
      .tech .line:nth-child(4)::after {
        right: -55px;
        width: 100px; } }
    @media (max-width: 991.98px) {
      .tech .line:nth-child(4)::after {
        right: -55px;
        width: 100px; } }
  .tech .line:nth-child(4)::before {
    display: block;
    position: absolute;
    top: -112px;
    right: 0;
    left: 0;
    width: 1px;
    height: 100px;
    margin: auto;
    border: 1px dashed #575a5d;
    content: "";
    z-index: -1; }
    @media (max-width: 1199.98px) {
      .tech .line:nth-child(4)::before {
        top: -70px;
        height: 70px; } }
    @media (max-width: 991.98px) {
      .tech .line:nth-child(4)::before {
        top: -70px;
        height: 70px; } }
  .tech .line:nth-child(5)::after {
    display: block;
    position: absolute;
    top: -110px;
    right: 0;
    left: 0;
    width: 1px;
    height: 100px;
    margin: auto;
    border: 1px dashed #575a5d;
    content: "";
    z-index: -1; }
    @media (max-width: 1199.98px) {
      .tech .line:nth-child(5)::after {
        top: -70px;
        height: 70px; } }
    @media (max-width: 991.98px) {
      .tech .line:nth-child(5)::after {
        top: -70px;
        height: 70px; } }

.icon-5 {
  height: 70px; }

.icon-6 {
  height: 70px; }

.icon-8 {
  height: 70px; }

#canvas {
  position: absolute;
  top: 0;
  right: -20%;
  padding-top: 50px;
  z-index: -1; }

#globekit-canvas-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  visibility: hidden; }

canvas#globekit-canvas {
  width: 100%;
  height: 100%;
  background: #000; }

.ambient-callout {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 20em;
  font-size: 0.8125rem;
  font-weight: 500;
  z-index: 1000; }

.ambient-callout span {
  display: block;
  padding-top: 0.55em;
  font-size: 0.8125rem;
  font-weight: 400; }

.ambient-callout em {
  display: block;
  padding-top: 0.8em;
  color: #a7a9ab;
  font-size: 0.625rem;
  font-weight: 400;
  text-transform: uppercase; }

.ambient-callout .contents {
  position: relative;
  -webkit-transform: translate3d(0, -15px, 0);
  transform: translate3d(0, -15px, 0);
  overflow: hidden; }

.ambient-callout.left .contents {
  -webkit-transform: translate3d(-100%, -15px, 0);
  transform: translate3d(-100%, -15px, 0); }

.ambient-callout .inner-contents {
  padding: 0.6em 0.7em;
  background: white; }

.ambient-callout.takeover .inner-contents {
  background: white; }

.ambient-callout .inner-contents {
  margin-left: 24px; }

.ambient-callout.left .inner-contents {
  margin-right: 24px;
  margin-left: 0; }

.ambient-callout.pulse-arc .inner-contents {
  margin-left: 36px; }

.ambient-callout.pulse-arc.left .inner-contents {
  margin-right: 36px;
  margin-left: 0; }

.ambient-callout {
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s; }

.ambient-callout.deemphasize {
  opacity: 0.2; }

.ambient-callout .inner-contents {
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  opacity: 0; }

.ambient-callout.in .inner-contents {
  opacity: 1; }

@media (max-width: 991.98px) {
  .ambient-callout {
    display: none; } }

.partners-image-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 400px;
  padding: 20px;
  transition: border-color 0.25s ease-in-out 0s;
  border-width: 1px;
  border-style: solid;
  border-color: #e1e2e4;
  text-align: center;
  border-image: initial;
  width: 300px;
  height: 300px; }
  .partners-image-block img {
    width: 100%; }
  @media (max-width: 1199.98px) {
    .partners-image-block {
      width: 190px;
      height: 190px; } }
  @media (max-width: 991.98px) {
    .partners-image-block {
      width: 225px;
      height: 225px;
      margin: 5px 0; } }

.image-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  padding: 20px;
  transition: border-color 0.25s ease-in-out 0s;
  border-width: 1px;
  border-style: solid;
  border-color: #e1e2e4;
  text-align: center;
  border-image: initial; }
  .image-block img {
    width: 80px; }
  @media (max-width: 991.98px) {
    .image-block {
      width: 150px;
      height: 150px; }
      .image-block img {
        width: 80px; } }

.how-works {
  font-size: 1.2rem; }

.quote img {
  max-height: 125px;
  border-radius: 50%; }

.quote h3 {
  font-size: 30px; }
